import React, { Component } from 'react';
import config from '../../config';
import ReCAPTCHA from "react-google-recaptcha";
import AnchorLink from 'react-anchor-link-smooth-scroll';

const recaptchaRef = React.createRef();

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            captchaOK: false,
            alertMessage: '',
            isErrorMessage: false,
            name: '',
            email: '',
            phone: '',
            units: 100,
            message: ''
        };

    }

    selectPlan1 = () => {
      this.setState({ message: "¡Hola! Me interesa el Plan Básico..." });
    }

    selectPlan2 = () => {
      this.setState({ message: "¡Hola! Me interesa el Plan Avanzado..." });
    }

    selectPlan3 = () => {
      this.setState({ message: "¡Hola! Me interesa el Plan Experto..." });
    }

    // const recaptchaRef = React.createRef();
    onChange = (value) => {
      console.log("Captcha onChange:", value);
      this.setState({ captchaOK: true });
    }

    onExpired = (value) => {
      console.log("Captcha onExpired:", value);
      this.setState({ captchaOK: false });
    }
    onErrored = (value) => {
      console.log("Captcha onErrored:", value);
      this.setState({ captchaOK: false });
    }

    handleSubmit = async event => {
      event.preventDefault();


      if (!this.state.captchaOK) {
        this.setState({ loading: false, alertMessage: 'Debes marcar el captcha'});
        return;
      }

      this.setState({ loading: true });
      try {
    	  const response = await this.contactSupport({
          ...this.props.fields,
    	    name: this.state.name,
          email: this.state.email,
          phone: this.state.phone,
          units: this.state.units,
          message: this.state.message + " (web) (unidades: " + this.state.units + ")"
        });
        console.log(response);
        this.setState({ loading: false, captchaOK: false, alertMessage: 'Hemos recibido tu mensaje, pronto nos comunicaremos contigo.', isErrorMessage: false, name: '', email: '', phone: '', message: '' });
        recaptchaRef.current.reset();
      } catch (e) {
        console.error(e);
        this.setState({ loading: false, captchaOK: false, alertMessage: 'Error al enviar mensaje. Favor intenta nuevamente', isErrorMessage: true });
      }
    }

    handleChange = event => {
      this.setState({
        [event.target.id]: event.target.value
      });
    }

    async contactSupport(contact) {
      const result = await fetch(config.apiGateway.URL + '/contact', {
        method: 'POST',
        headers: { 'content-type': 'application/json'},
        body: JSON.stringify(contact)
      });
      return result;
    }

    render() {
        const { name, email, phone, units, message, loading, alertMessage } = this.state;
        return (
          <React.Fragment>
          <section id="pricing" className="pricing-area ptb-100 pt-0">
              <div className="container">
                  <div className="saas-section-title">
                      <h2>Nuestros planes</h2>
                      <div className="bar"></div>
                      <p>Elige el plan mensual que m&aacute;s se acomoda a las necesidades de tu edificio.</p>
                  </div>

                  <div className="row">
                      <div className="col-lg-4 col-md-6">
                          <div className="pricing-table">
                              <div className="pricing-header">
                                  <div className="icon">
                                      <img src={require("../../images/icon1.png")} alt="icon" />
                                  </div>

                                  <h3>Plan B&aacute;sico</h3>
                              </div>

                              <ul className="pricing-features">
                                  <li className="active">Visitas</li>
                                  <li className="active">Encomiendas</li>
                                  <li className="active">Residentes</li>
                                  <li className="unactive">Mascotas</li>
                                  <li className="unactive">Reservas</li>
                                  <li className="unactive">Proveedores</li>
                                  <li className="unactive">Multas</li>
                                  <li className="unactive">Lista negra</li>
                                  <li className="unactive">Comunicados</li>
                                  <li className="unactive">Lectura de cédula de identidad</li>
                                  <li className="unactive">Usuarios ilimitados</li>
                                  <li className="unactive">Soporte 24x7</li>
                                  <li className="unactive">Reportes</li>
                              </ul>

                              <div className="price">
                                  <span>Mensual</span>
                              </div>

                              <AnchorLink
                                  onClick={this.selectPlan1}
                                  offset={() => -10}
                                  className="btn btn-primary"
                                  href="#contact"
                              >
                                  Lo quiero!
                              </AnchorLink>
                          </div>
                      </div>

                      <div className="col-lg-4 col-md-6">
                          <div className="pricing-table active">
                              <div className="pricing-header">
                                  <div className="icon">
                                      <img src={require("../../images/icon2.png")} alt="icon" />
                                  </div>

                                  <h3>Plan Avanzado</h3>
                              </div>

                              <ul className="pricing-features">
                                  <li className="active">Visitas</li>
                                  <li className="active">Encomiendas</li>
                                  <li className="active">Residentes</li>
                                  <li className="active">Mascotas</li>
                                  <li className="active">Reservas</li>
                                  <li className="active">Proveedores</li>
                                  <li className="active">Multas</li>
                                  <li className="unactive">Lista negra</li>
                                  <li className="unactive">Comunicados</li>
                                  <li className="unactive">Lectura de cédula de identidad</li>
                                  <li className="unactive">Usuarios ilimitados</li>
                                  <li className="unactive">Soporte 24x7</li>
                                  <li className="unactive">Reportes</li>
                              </ul>

                              <div className="price">
                                  <span>Mensual</span>
                              </div>

                              <AnchorLink
                                  onClick={this.selectPlan2}
                                  offset={() => -10}
                                  className="btn btn-primary"
                                  href="#contact"
                              >
                                  Lo quiero!
                              </AnchorLink>
                          </div>
                      </div>

                      <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                          <div className="pricing-table">
                              <div className="pricing-header">
                                  <div className="icon">
                                      <img src={require("../../images/icon3.png")} alt="icon" />
                                  </div>

                                  <h3>Plan Experto</h3>
                              </div>

                              <ul className="pricing-features">
                                  <li className="active">Visitas</li>
                                  <li className="active">Encomiendas</li>
                                  <li className="active">Residentes</li>
                                  <li className="active">Mascotas</li>
                                  <li className="active">Reservas</li>
                                  <li className="active">Proveedores</li>
                                  <li className="active">Multas</li>
                                  <li className="active">Lista negra</li>
                                  <li className="active">Comunicados</li>
                                  <li className="active">Lectura de cédula de identidad</li>
                                  <li className="active">Usuarios ilimitados</li>
                                  <li className="active">Soporte 24x7</li>
                                  <li className="active">Reportes</li>
                              </ul>

                              <div className="price">
                                  <span>Mensual</span>
                              </div>

                              <AnchorLink
                                  onClick={this.selectPlan3}
                                  offset={() => -10}
                                  className="btn btn-primary"
                                  href="#contact"
                              >
                                  Lo quiero!
                              </AnchorLink>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
            <section id="contact" className="contact-area ptb-100 pt-0">
                <div className="container">
                    <div className="saas-section-title">
                        <h2>Contáctanos</h2>
                        <div className="bar"></div>
                        <p>¿Tienes dudas? ¿Quieres una demo?</p>
                    </div>

                    <div className="contact-form">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-12">
                                <div className="contact-info-box">
                                    <p>Nuestro equipo está para ayudarte. Si tienes dudas o quieres una demo, sólo contáctanos.</p>

                                    <span>También puedes escribirnos directo a:
                                        <a href="mailto:contacto@deptogestion.com">contacto@deptogestion.com</a>
                                    </span>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12">
                                <form id="contactForm" onSubmit={this.handleSubmit}>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label>Nombre</label>
                                                <input
                                                  id="name"
                                                  type="text"
                                                  className="form-control"
                                                  required={true}
                                                  data-error="Ingresa nombre"
                                                  value={name}
                                                  onChange={e => this.handleChange(e)}/>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label>Email</label>
                                                <input
                                                  id="email"
                                                  type="email"
                                                  className="form-control"
                                                  required={true}
                                                  data-error="Ingresa tu email"
                                                  value={email}
                                                  onChange={e => this.handleChange(e)}/>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label>Teléfono</label>
                                                <input
                                                  id="phone"
                                                  type="number"
                                                  className="form-control"
                                                  required={true}
                                                  data-error="Ingresa tu teléfono"
                                                  value={phone}
                                                  onChange={e => this.handleChange(e)}/>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label>&#8470; Departamentos, casas u oficinas</label>
                                                <input
                                                  id="units"
                                                  type="number"
                                                  className="form-control"
                                                  required={true}
                                                  data-error="Ingresa tu teléfono"
                                                  value={units}
                                                  onChange={e => this.handleChange(e)}/>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label>Mensaje</label>
                                                <textarea
                                                  id="message"
                                                  className="form-control"
                                                  cols="30"
                                                  rows="4"
                                                  required={true}
                                                  data-error="Ingresa tu mensaje"
                                                  value={message}
                                                  onChange={e => this.handleChange(e)}/>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <ReCAPTCHA
                                          ref={recaptchaRef}
                                          sitekey={config.captcha.KEY}
                                          onChange={this.onChange}
                                          onExpired={this.onExpired}
                                          onErrored={this.onErrored}
                                          hl={"es"}
                                        />
                                        <div className="col-lg-12 col-md-12">

                                            <button type="submit" className="btn btn-primary" disabled={loading}>
                                            {loading ? "Enviando...": "Enviar"}
                                            </button>
                                            <div id="msgSubmit" className="h3 text-center hidden"></div>
                                            <div id="msgSubmit" className="h3 text-center">{alertMessage}</div>
                                            <div className="clearfix"></div>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </React.Fragment>
        );
    }
}

export default Contact;
