import React from 'react'
import NavbarSix from '../components/Layouts/NavbarSix';
import Footer from '../components/Layouts/Footer';
import { Link } from "react-router-dom";

class NotFound extends React.Component {
    static getInitialProps({ res, err }) {
        const statusCode = res ? res.statusCode : err ? err.statusCode : null;
        return { statusCode };
    }

    render() {
        return (
            <React.Fragment>
                <NavbarSix />

                <section className="page-title-area jarallax" data-jarallax='{"speed": 0.2}'>
                    <div className="container">
                        <h2>Algo sali&oacute; mal... la p&aacute;gina que buscas no existe</h2>
                        <div className="error-content">
                            <Link to="/">
                              <img src={require("../images/404.png")} alt="error" />
                            </Link>
                        </div>
                    </div>
                </section>

                <Footer />
            </React.Fragment>
        );
    }
}

export default NotFound;
