import React, { Component } from 'react';
import config from '../../config';
import ReCAPTCHA from "react-google-recaptcha";

const recaptchaRef = React.createRef();

class Unsubscribe extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            captchaOK: false,
            alertMessage: '',
            isErrorMessage: false,
            email: '',
            unit: '',
            selectedOption: 'No quiero recibir estos correos'
        };

    }
    // const recaptchaRef = React.createRef();
    onChange = (value) => {
      console.log("Captcha onChange:", value);
      this.setState({ captchaOK: true });
    }

    onExpired = (value) => {
      console.log("Captcha onExpired:", value);
      this.setState({ captchaOK: false });
    }
    onErrored = (value) => {
      console.log("Captcha onErrored:", value);
      this.setState({ captchaOK: false });
    }

    handleSubmit = async event => {
      event.preventDefault();


      if (!this.state.captchaOK) {
        this.setState({ loading: false, alertMessage: 'Debes marcar el captcha'});
        return;
      }

      this.setState({ loading: true });
      try {
    	  const response = await this.contactSupport({
          ...this.props.fields,
    	    name: '', phone: '',
          email: this.state.email,
          message: "DESUSCRIBIR <br />Unidad:" + this.state.unit + "<br />Motivo: " + this.state.selectedOption
        });
        console.log(response);
        this.setState({ loading: false,
          captchaOK: false,
          alertMessage: 'Hemos recibido tu solicitud, pronto te desuscribiremos.',
          isErrorMessage: false, unit: '', email: '',
          selectedOption: 'No quiero recibir estos correos' });
        recaptchaRef.current.reset();
      } catch (e) {
        console.error(e);
        this.setState({ loading: false, captchaOK: false, alertMessage: 'Error al enviar mensaje. Favor intenta nuevamente', isErrorMessage: true });
      }
    }

    handleChange = event => {
      this.setState({
        [event.target.id]: event.target.value
      });
    }

    onValueChange = event => {
      this.setState({
        selectedOption: event.target.value
      });
    }

    async contactSupport(contact) {
      const result = await fetch(config.apiGateway.URL + '/contact', {
        method: 'POST',
        headers: { 'content-type': 'application/json'},
        body: JSON.stringify(contact)
      });
      return result;
    }

    render() {
        const { email, unit, loading, alertMessage } = this.state;
        return (
          <React.Fragment>

            <section id="contact" className="contact-area ptb-100 pt-0">
                <div className="container">
                    <div className="saas-section-title">
                        <br />
                        <h2>Desuscripción</h2>
                        <div className="bar"></div>
                        <p>¿No deseas recibir estos emails?</p>
                    </div>

                    <div className="contact-form">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-12">
                                <div className="contact-info-box">
                                    <p>Nuestro equipo está para ayudarte. Si no deseas recibir estos emails, solo completa el formulario y te desuscribiremos.</p>

                                    <span>También puedes escribirnos directo a:
                                        <a href="mailto:contacto@deptogestion.com">contacto@deptogestion.com</a>
                                    </span>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12">
                                <form id="contactForm" onSubmit={this.handleSubmit}>
                                    <div className="row">

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label>Email</label>
                                                <input
                                                  id="email"
                                                  type="email"
                                                  className="form-control"
                                                  required={true}
                                                  data-error="Ingresa tu email"
                                                  value={email}
                                                  maxlength="64"
                                                  onChange={e => this.handleChange(e)}/>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label>Nº Departamento, casa o parcela</label>
                                                <input
                                                  id="unit"
                                                  type="text"
                                                  className="form-control"
                                                  required={true}
                                                  data-error="Ingresa nombre"
                                                  value={unit}
                                                  maxlength="10"
                                                  onChange={e => this.handleChange(e)}/>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                              <label>Motivo principal</label>
                                              <div className="radio">
                                                <label>
                                                  <input
                                                    type="radio"
                                                    value="No quiero recibir estos correos"
                                                    checked={this.state.selectedOption === "No quiero recibir estos correos"}
                                                    onChange={this.onValueChange}
                                                  />
                                                  &nbsp;No quiero recibir estos correos
                                                </label>
                                              </div>
                                              <div className="radio">
                                                <label>
                                                  <input
                                                    type="radio"
                                                    value="Ya no vivo ahí"
                                                    checked={this.state.selectedOption === "Ya no vivo ahí"}
                                                    onChange={this.onValueChange}
                                                  />
                                                  &nbsp;Ya no vivo ahí
                                                </label>
                                              </div>
                                              <div className="radio">
                                                <label>
                                                  <input
                                                    type="radio"
                                                    value="Otro"
                                                    checked={this.state.selectedOption === "Otro"}
                                                    onChange={this.onValueChange}
                                                  />
                                                  &nbsp;Otro
                                                </label>
                                              </div>
                                            </div>
                                        </div>
                                        <ReCAPTCHA
                                          ref={recaptchaRef}
                                          sitekey={config.captcha.KEY}
                                          onChange={this.onChange}
                                          onExpired={this.onExpired}
                                          onErrored={this.onErrored}
                                          hl={"es"}
                                        />
                                        <div className="col-lg-12 col-md-12">

                                            <button type="submit" className="btn btn-primary" disabled={loading}>
                                            {loading ? "Enviando...": "Enviar"}
                                            </button>
                                            <div id="msgSubmit" className="h3 text-center hidden"></div>
                                            <div id="msgSubmit" className="h3 text-center">{alertMessage}</div>
                                            <div className="clearfix"></div>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </React.Fragment>
        );
    }
}

export default Unsubscribe;
