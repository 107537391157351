import React, { Component } from 'react';
import NavbarThree from '../components/Layouts/NavbarThree';
import Footer from '../components/Layouts/Footer';
import About from '../components/Home/About';
import Banner from '../components/Home/Banner';
import Services from '../components/Home/Services';
import Features from '../components/Home/Features';
import Funfact from '../components/Home/Funfact';
import Contact from '../components/Home/Contact';
import Faq from '../components/Home/Faq';

class Home extends Component {
    render() {
        return (
            <React.Fragment>
              <NavbarThree />
              <Banner />
              <About />
              <Features />



              {/*<NewFeatures />*/}
              <Services />
              <Funfact />
              {/*
              <Feedback />
              <Pricing />
              <Faq />
              <Trial />
              <Partner />
              <Blog />
              <Subscribe />
              */}
              <Contact />
              <Faq />
              <Footer />
            </React.Fragment>
        );
    }
}

export default Home;
