import React, { Component } from 'react';
const WOW = require('wowjs');

class Features extends Component {

    componentDidMount(){
        new WOW.WOW({
            boxClass: 'wow',
            animateClass: 'animated',
            offset: 20,
            mobile:       true,
            live:         false,
        }).init();
    }

    render() {
        return (
            <section id="features" className="features-area-two bg-f7fafd ptb-100">
                <div className="container">
                    <div className="saas-section-title">
                        <h2>Características destacadas</h2>
                        <div className="bar"></div>
                        <p>Nuestro software cuenta con características únicas en el mercado.</p>
                    </div>

                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="features-content">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="box">
                                            <i className="fas fa-exclamation-triangle"></i>
                                            Multas
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="box">
                                            <i className="fas fa-users"></i>
                                            Residentes
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="box">
                                            <i className="fas fa-qrcode"></i>
                                            Lector de cédula nacional para visitas
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="box">
                                            <i className="far fa-envelope"></i>
                                            Notificaciones
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="box">
                                            <i className="fas fa-wrench"></i>
                                            Proveedores
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="box">
                                            <i className="fas fa-car"></i>
                                            Estacionamientos en uso
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="box">
                                            <i className="fas fa-book"></i>
                                            Registra novedades y notifica automáticamente eventos críticos
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="box">
                                            <i className="fas fa-box-open"></i>
                                            Evita robos. Entrega las encomiendas sólo a residentes registrados.
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="box">
                                            <i className="fas fa-lock"></i>
                                            Acceso controlado
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="box">
                                            <i className="fas fa-check-double"></i>
                                            Fácil de usar
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="features-image">
                                <img src={require("../../images/main-pic.png")} className="wow fadeInUp" data-wow-delay="0.6s" alt="main-pic" />
                                <img src={require("../../images/circle-shape.png")} className="rotate-image rotateme" alt="circle" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Features;
