import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import ModalVideo from 'react-modal-video';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import BackgroundSlider from 'react-background-slider'

import image1 from '../../images/banner1.jpg'
import image2 from '../../images/banner2.jpg'
import image3 from '../../images/banner3.jpg'

class Banner extends Component {

    state = {
        isOpen: false
    };

    openModal = () => {
        this.setState({isOpen: true})
    };

    render() {
        return (
            <React.Fragment>
            <BackgroundSlider images={[image1, image2, image3]} duration={6} transition={2}/>
                <div id="home" className="main-banner jarallax" data-jarallax='{"speed": 0.2}'>
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="container">
                                <div className="main-banner-content">
                                    <h1>_<span>Conserjería 2.0</span></h1>
                                    <h1>Encomiendas, visitas, residentes, reservas y más.</h1>
                                    <p>Suma tu comunidad a la transformación digital. Tecnología innovadora, de bajo costo, alto rendimiento y 100% web </p>
                                    <div className="btn-box">
                                      {/*
                                        <Link to="#"className="popup-youtube video-btn"
                                                onClick={e => {e.preventDefault(); this.openModal()}}>
                                                <i className="fab fa-google-play"></i>
                                                Watch our video
                                        </Link>
                                        */}
                                        <AnchorLink offset={() => 0} className="btn btn-primary" href="#about">Empecemos!</AnchorLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalVideo
                    channel='youtube'
                    isOpen={this.state.isOpen}
                    videoId='szuchBiLrEM'
                    onClose={() => this.setState({isOpen: false})}
                />
            </React.Fragment>
        );
    }
}

export default Banner;
