const dev = {
  app: 'http://app.labs.deptogestion.com',
  captcha: {
    KEY: '6Le_qaoZAAAAAPim2oK7_fH53EtMuaMV2rbvAM4J'
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://api.labs.deptogestion.com"
  }
};

const prod = {
  app: 'http://app.deptogestion.com',
  captcha: {
    KEY: '6Le_qaoZAAAAAPim2oK7_fH53EtMuaMV2rbvAM4J'
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api.deptogestion.com"
  }
};

const config = process.env.REACT_APP_ENV === 'production'
  ? prod
  : dev;

config.version = "v.1.1.1";

export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
