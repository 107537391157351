import React, { Component } from 'react';
import { Link } from "react-router-dom";
import AnchorLink from 'react-anchor-link-smooth-scroll';
import config from '../../config';

class Footer extends Component {
    render() {
        return (
            <footer className="footer-area bg-fffcf4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-footer-widget">
                                <div className="logo">
                                    <Link to="/">
                                        <img src={require('../../images/black-logo.png')} alt=""/>
                                    </Link>
                                </div>

                                <ul className="contact-info">
                                    <li>
                                        <a href="mailto:contacto@deptogestion.com">contacto@deptogestion.com</a>
                                    </li>
                                    <li>
                                        <Link to="/">www.deptogestion.com</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                            <div className="single-footer-widget pl-3">
                                <h3>Acerca de...</h3>

                                <ul className="list">
                                    <li>
                                      <AnchorLink
                                          onClick={this.toggleNavbar}
                                          offset={() => 100}
                                          href="#home"
                                      >
                                          Inicio
                                      </AnchorLink>
                                    </li>
                                    <li>
                                        <AnchorLink
                                            onClick={this.toggleNavbar}
                                            offset={() => 0}
                                            href="#about"
                                        >
                                            Nosotros
                                        </AnchorLink>
                                    </li>
                                    <li>
                                        <AnchorLink
                                            onClick={this.toggleNavbar}
                                            offset={() => 0}
                                            href="#features"
                                        >
                                            Características
                                        </AnchorLink>
                                    </li>
                                              </ul>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                            <div className="single-footer-widget pl-5">
                                <h3>Servicio</h3>

                                <ul className="list">
                                <li>
                                    <AnchorLink
                                        onClick={this.toggleNavbar}
                                        offset={() => 0}
                                        href="#services"
                                    >
                                        Servicio
                                    </AnchorLink>
                                </li>
                                <li>
                                    <AnchorLink
                                        onClick={this.toggleNavbar}
                                        offset={() => 0}
                                        href="#testimonials"
                                    >
                                        Clientes
                                    </AnchorLink>
                                </li>
                                <li>
                                    <AnchorLink
                                        onClick={this.toggleNavbar}
                                        offset={() => 0}
                                        href="#pricing"
                                    >
                                        Planes
                                    </AnchorLink>
                                </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-6">
                            <div className="single-footer-widget">
                                <h3>Mi Cuenta</h3>

                                <ul className="list">
                                    <li>
                                        <a href={config.app + '/login'}>Acceso conserjes</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="copyright-area">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7 col-md-7">
                                <p><i className="far fa-copyright"></i> {new Date().getFullYear()} <a href="/">DeptoGestion</a>. Todos los derechos reservados</p>
                            </div>

                            <div className="col-lg-5 col-md-5">
                                <ul>
                                    <li>
                                        <a href="https://www.facebook.com/deptogestioncom" target="_blank" rel="noopener noreferrer">
                                            <i className="fab fa-facebook-f"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/deptogestioncl" target="_blank" rel="noopener noreferrer">
                                            <i className="fab fa-twitter"></i>
                                        </a>
                                    </li>

                                    <li>
                                        <a href="https://www.instagram.com/deptogestioncom" target="_blank" rel="noopener noreferrer">
                                            <i className="fab fa-instagram"></i>
                                        </a>
                                    </li>
                                    {/*
                                    <li>
                                        <a href="https://www.linkedin.com/deptogestioncl" target="_blank" rel="noopener noreferrer">
                                            <i className="fab fa-linkedin-in"></i>
                                        </a>
                                    </li>
                                    */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="map2">
                    <img src={require('../../images/map2.png')} alt="map" />
                </div>
            </footer>
        );
    }
}

export default Footer;
