import React, { Component } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";

class Funfact extends Component {

    state = {
        didViewCountUp: false
    };

    onVisibilityChange = isVisible => {
        if (isVisible) {
            this.setState({ didViewCountUp: true });
        }
    };

    render() {
        return (
            <section className="funfacts-area-three ptb-100 pt-0">
                <div className="container">
                    <div className="saas-section-title">
                        <h2>¿Qué dicen los números?</h2>
                        <div className="bar"></div>
                        <p>Manejamos un alto volumen de transacciones manteniendo un óptimo rendimiendo.</p>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-4">
                            <div className="funfact-item">
                                <h3><span className="odometer" data-count="180">
                                    <VisibilitySensor
                                        onChange={this.onVisibilityChange}
                                        offset={{
                                            top: 10
                                        }}
                                        delayedCall
                                    >
                                        <CountUp
                                            start={0}
                                            end={
                                                this.state.didViewCountUp
                                                    ? 65000
                                                    : 0
                                            }
                                            duration={3}
                                        />
                                    </VisibilitySensor>
                                    </span>+</h3>
                                <p>Encomiendas</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-4">
                            <div className="funfact-item">
                                <h3><span className="odometer" data-count="20">
                                    <VisibilitySensor
                                        onChange={this.onVisibilityChange}
                                        offset={{
                                            top: 10
                                        }}
                                        delayedCall
                                    >
                                        <CountUp
                                            start={0}
                                            end={
                                                this.state.didViewCountUp
                                                    ? 120000
                                                    : 0
                                            }
                                            duration={3}
                                        />
                                    </VisibilitySensor>
                                    </span>+</h3>
                                <p>Visitas</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-4">
                            <div className="funfact-item">
                                <h3><span className="odometer" data-count="70">
                                    <VisibilitySensor
                                        onChange={this.onVisibilityChange}
                                        offset={{
                                            top: 10
                                        }}
                                        delayedCall
                                    >
                                        <CountUp
                                            start={0}
                                            end={
                                                this.state.didViewCountUp
                                                    ? 5000
                                                    : 0
                                            }
                                            duration={3}
                                        />
                                    </VisibilitySensor></span>+</h3>
                                <p>Comunicaciones</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="map-bg">
                    <img src={require("../../images/map.png")} alt="map" />
                </div>
            </section>
        );
    }
}

export default Funfact;
