import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

class About extends Component {
    render() {
        return (
            <section id="about" className="about-area ptb-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-image">
                                <img src={require('../../images/about.png')} alt="about" />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <h2>¿Qué es <span>DeptoGestion</span>?</h2>
                                <p>Es un software de apoyo a la gestión de las conserjerías, desarrollado de la mano de conserjes y administradores, que facilita las tareas rutinarias de conserjes, brindando un mejor servicio a los residentes.</p>

                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="single-inner-content">
                                            <div className="title">
                                                <div className="icon">
                                                    <i className="fas fa-users"></i>
                                                </div>

                                                <h3>Control de visitas</h3>
                                            </div>

                                            <p>Registra todas las visitas del edificio con o sin vehículo y contabiliza el tiempo de uso de estacionamientos</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="single-inner-content">
                                            <div className="title">
                                                <div className="icon">
                                                    <i className="fas fa-dolly"></i>
                                                </div>

                                                <h3>Registro de encomiendas</h3>
                                            </div>

                                            <p>Puedes registrar lo que quieras… desde un sobre hasta una gran encomienda...</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="single-inner-content">
                                            <div className="title">
                                                <div className="icon">
                                                    <i className="fas fa-paw"></i>
                                                </div>

                                                <h3>Registro de mascotas</h3>
                                            </div>

                                            <p>Mantén actualizada tu base de datos con las mascotas del edificio.</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="single-inner-content">
                                            <div className="title">
                                                <div className="icon">
                                                    <i className="fas fa-calendar-alt"></i>
                                                </div>

                                                <h3>Reserva de espacios comunes</h3>
                                            </div>

                                            <p>Reserva los espacios comunes del edificio. Sin errores.</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="single-inner-content">
                                            <div className="title">
                                                <div className="icon">
                                                    <i className="fas fa-user-slash"></i>
                                                </div>

                                                <h3>Lista negra</h3>
                                            </div>

                                            <p>Alertas a conserjes para no permitir el acceso a personas en lista negra.</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="single-inner-content">
                                            <div className="title">
                                                <div className="icon">
                                                    <i className="fas fa-bullhorn"></i>
                                                </div>

                                                <h3>Comunicados</h3>
                                            </div>

                                            <p>Mantén informado a los residentes en todo momento. Notifica las encomiendas, reservas, visitas y noticias importantes.</p>
                                        </div>
                                    </div>
                                </div>

                                <AnchorLink offset={() => 0} className="btn btn-primary" href="#features">Ver más...</AnchorLink>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="circle"><img src={require('../../images/circle.png')} alt="cricle" /></div>
            </section>
        );
    }
}

export default About;
