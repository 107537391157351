import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Routes from "./Routes";

import { Preloader, Placeholder } from 'react-preloading-screen';
import Loader from './components/Shared/Loader';
import GoTop from './components/Shared/GoTop';

import './assets/css/bootstrap.min.css';
import './assets/css/animate.min.css';
import './assets/css/fontawesome.min.css';
import './assets/css/style.scss';
import './assets/css/responsive.scss';

class App extends Component {
  render() {
    return (
      <div className="App">
        <Preloader>
            <Routes />
            <GoTop scrollStepInPx="50" delayInMs="16.66" />
            <Placeholder>
                <Loader />
            </Placeholder>
        </Preloader>
      </div>
    );
  }
}

export default withRouter(App);
