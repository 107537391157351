import React from "react";
import { Route, Switch } from "react-router-dom";

import Home from './pages/Home';
import NotFound from './pages/NotFound';
import Unsubscribe from './pages/Unsubscribe';

export default ({ childProps }) =>
  <Switch>
    <Route path="/" exact component={Home} props={childProps} />
    <Route path="/Unsubscribe" exact component={Unsubscribe} props={childProps} />
    <Route component={NotFound} />
  </Switch>;
