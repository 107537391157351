import React, { Component } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion';
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

class Faq extends Component {
    render() {
        return (
            <section id="faq" className="faq-area ptb-100 pt-0">
                <div className="container">
                    <div className="saas-section-title">
                        <h2>Preguntas frecuentes</h2>
                        <div className="bar"></div>
                        <p>Resolvemos tus dudas.</p>
                    </div>

                    <div className="faq">
                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        ¿Con qué equipamiento necesito contar en mi edificio para trabajar con DeptoGestion?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        Simplemente tu edificio debe contar con un computador o tablet, y una conexión a internet. Adicionalmente te podemos proveer del lector que permite leer cédulas de identidad y encomiendas, y además la cámara web.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        ¿Qué debo instalar?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        No debes instalar nada. Solo tener un navegador web y listo :)
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        ¿Cómo capacito a mis conserjes?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        Nosotros capacitamos a los conserjes para que saquen el máximo provecho de la plataforma.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        ¿Hay límites en los registros que puedo almacenar?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        No. No limitamos la cantidad de registros :)
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        ¿En cuánto tiempo puedo tener mi plataforma andando?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        En menos de 48 horas puedes tener operando la plataforma en el edificio.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </div>
            </section>
        );
    }
}

export default Faq;
